import React from 'react'
import Helmet from 'react-helmet'

import { Layout } from '../components/Base/Layout'

import looking from '../assets/images/about3.png'

export default function WebsiteUs() {

  return (
    <>
      <Layout>
      <Helmet title="Ten Years" />

      <article className="hero">

        <header>
          <div className="container">
            <div className="flex-content">
              <div>
                <div class="hero">
                  <h1>YsFamily</h1>
                </div>

                <p className="subtitle small">
                2014年，四个行李，两口人。<br/>
                2015年，一家团聚，三口人。<br/>
                2019年，添一新丁，四口人。<br/>
                </p>

                <p className="subtitle small">
                来美9年，读书4年，创业5年。
                经历奥巴马的蜜月中美，深科技与厚资本相得益彰共享盛世。
                经历特朗普的中美贸易战，因禁止中国资本投资美国公司，几多创友借贷度日破产还钱。
                经历新冠，美国边境封锁，开放的第一周6岁女儿独自一人从中国回美国。
                经历资本寒冬，数个投资人撤回意向，在最后关头融到第一笔钱。<br/>
                </p>

                <p className="subtitle small">
                我们或许走得慢，但从不后退。
                </p>

                <p className="subtitle small">
                
                </p>

              </div>
              <img src={looking} alt="US" className="main-image-about" />
            </div>

          </div>
        </header>

      </article>




      </Layout>
    </>
  )
}


